import { Link } from "gatsby";
import React, { useState } from "react";

import { navigate } from "gatsby";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { isAuthed } from "../utils/Misc";
import { logoutUser } from "../state/user";

const Header = props => {

	const [navbarOpen, setNavbarOpen] = useState(false);
	const toggleNavbar = () => setNavbarOpen(!navbarOpen);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

	return (
		<Navbar color="light" light expand="md" className="mt-3 mb-3">
			<Link to="/" className="navbar-brand">{ props.siteTitle }</Link>
			<NavbarToggler onClick={ toggleNavbar }/>
			<Collapse isOpen={ navbarOpen } navbar>
				<Nav className="ml-auto" navbar>
					{/* <NavItem>
						<Link to="/features" className="nav-link">Funktionen</Link>
					</NavItem> */}
					{/*<NavItem>
						<Link to="/pricing" className="nav-link">Pricing</Link>
					</NavItem>*/}
					<NavItem>
						<Link to="/faq" className="nav-link">FAQ</Link>
					</NavItem>
				</Nav>
				<Nav className="ml-lg-4" navbar>
					{ isAuthed(props.token) &&
						<Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle nav caret>
								{ props.user.firstName } { props.user.lastName }
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem><Link to="/dashboard">Dashboard</Link></DropdownItem>
								<DropdownItem divider />
								<DropdownItem onClick={() => {
									props.dispatch(logoutUser());
									navigate("/");
								}}>Abmelden</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					}
					{ !isAuthed(props.token) &&
						<>
							<NavItem>
								<Link to="/login" className="nav-link">Anmelden</Link>
							</NavItem>
							<NavItem>
								<Link to="/signup" className="nav-link nav-signup">Registrieren</Link>
							</NavItem>
						</>
					}
				</Nav>
			</Collapse>
		</Navbar>
	);
}

export default connect(state => ({
	token: state.user.token,
	user: state.user.user,
}))(Header);
