/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`,
	);

	const metaDescription = description || site.siteMetadata.description;

	return (
		<>
			<Helmet
				htmlAttributes={ {
					lang,
				} }
				title={ title }
				titleTemplate={ `%s | ${ site.siteMetadata.title }` }
				meta={ [
					{
						name: `description`,
						content: metaDescription,
					},
					{
						property: `og:title`,
						content: title,
					},
					{
						property: `og:description`,
						content: metaDescription,
					},
					{
						property: `og:type`,
						content: `website`,
					},
					{
						name: `twitter:card`,
						content: `summary`,
					},
					{
						name: `twitter:creator`,
						content: site.siteMetadata.author,
					},
					{
						name: `twitter:title`,
						content: title,
					},
					{
						name: `twitter:description`,
						content: metaDescription,
					},
				].concat(meta) }
			>
				{/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-158047522-1"></script> */}
			</Helmet>
		</>
	);
}

SEO.defaultProps = {
	lang: `de`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
