import { Component } from "react";
import { navigate } from "gatsby-link";

class Redirect extends Component {
	componentDidMount() {
		navigate(this.props.to);
	}

	render() {
		return null;
	}
}

export default Redirect;
