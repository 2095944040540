import { Col, Row } from "reactstrap";
import React from "react";
import Background from "../images/bg-img.jpg";


const ContentContainer = ({ title, subtitle, half, centerTitle, titleRowClass, gutless, children, className }) => (
		<>
			<img src={ Background } className={`background ${half && "background-half"}`} alt="background gradient"/>
			{ (title || subtitle) &&
			<Row className={`mt-6 mb-4 ${titleRowClass}`}>
				<Col sm="12" className={centerTitle && "text-center"}>
					{ title && <h1 className="headline">{ title }</h1> }
					{ subtitle && <p className="mt-3">{ subtitle }</p> }
				</Col>
			</Row>
			}
			<Row className={`${(title || subtitle) ? "mt-2" : "mt-8"}`}>
				<Col sm="12" className={`compression-container ${ gutless ? "p-0" : "p-5"} ${ className ? className : "" }`}>
					{ children }
				</Col>
			</Row>
		</>
	);

export default ContentContainer;

