import React from "react";
import { Router } from "@reach/router";
import Redirect from "../../components/Redirect";
import ConfirmEmail from "../../pages-client/ConfirmEmail";
import PasswordReset from "../../pages-client/PasswordReset";

const ConfirmationIndex = () => (
	<Router>
		<ConfirmEmail path="/confirm/email/:token"/>
		<PasswordReset path="/confirm/password/:token"/>

		<Redirect to="/" default />
	</Router>
);

export default ConfirmationIndex;
