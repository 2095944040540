import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import axios from "axios";
import { Link } from "gatsby";
import ContentContainer from "../components/ContentContainer";
import CheckIcon from "../images/icon-check.svg";
import CrossIcon from "../images/icon-cross.svg";

const ConfirmEmail = ({ token }) => {

	const [confirmResult, setConfirmResult] = useState({ error: false });

	useEffect(() => {
		async function axiosPost() {
			try {
				const res = await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/confirmations/email`, { token });
				setConfirmResult({ ...res.data, status: res.status });
			} catch (err) {
				setConfirmResult({ error: err });
			}
		}
		axiosPost();
	}, []);

	return (
		<Layout>
			<SEO title="Email bestätigen"/>
			<ContentContainer title="Email bestätigen" subtitle="Der letzte Schritt zum eigenen Account .." half>
				{ confirmResult.error &&
				<div className="text-center">
					<img className="icon50" src={ CrossIcon } alt="email confirmation error"/>
					<h1 className="mt-4">{ confirmResult.error.message }</h1>
				</div>
				}
				{ !confirmResult.error && confirmResult.status === 204 &&
				<div className="text-center">
					<img className="icon50" src={ CheckIcon } alt="email confirmation successful"/>
					<h1 className="mt-4">Du hast erfolgreich deine Email bestätigt! Danke!</h1>
					<p>Du kannst dich jetzt <Link to="/login" className="color-orange">anmelden</Link>.</p>
				</div>
				}
			</ContentContainer>
		</Layout>
	);
};

export default ConfirmEmail;
