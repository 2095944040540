import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import axios from "axios";
import { Link } from "gatsby";
import ContentContainer from "../components/ContentContainer";
import CheckIcon from "../images/icon-check.svg";
import { navigate } from "gatsby-link";
import { Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";

const PasswordReset = ({ token }) => {

	const [tokenChecked, setTokenChecked] = useState(false);
	useEffect(() => {
		async function checkToken() {
			try {
				await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/confirmations/password`, { token });
				setTokenChecked(true);
			} catch (err) {
				console.error(err);
				navigate("/");
			}
		}
		checkToken();
	}, []);

	const [resetData, setResetData] = useState({
		password: "",
		confirmPassword: "",
		touched: false,
		valid: false,
		status: "",
	});

	useEffect(() => {
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{8,}$/;
		setResetData({ ...resetData, valid: passwordRegex.test(resetData.password) });
	}, [resetData.password]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setResetData({ ...resetData, touched: true });

		if (resetData.valid) {
			setResetData({ ...resetData, status: "SENT" });
			try {
				await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/confirmations/password`, { token, password: resetData.password });
				setResetData({ ...resetData, status: "SUCCESS" });
			} catch (err) {
				console.error(err);
				setResetData({ ...resetData, status: "ERROR" });
			}
		}
	};

	return (
		<Layout>
			<SEO title="Passwort zurücksetzen"/>
			<ContentContainer title="Passwort zurücksetzen" subtitle="" half>
				{ tokenChecked &&
				<>
					{ resetData.status !== "SUCCESS" &&
					<div className="text-center">
						<div className="text-center mw-75 d-block mx-auto">
							<h1 className="font-weight-bold mt-4">Neues Passwort setzen</h1>
							<p className="mt-3 color-subtext">Nur noch ein Schritt zum neuen Passwort. Yeah!</p>
						</div>
						<Form onSubmit={ e => handleSubmit(e) } className="mw-50 d-block mx-auto">
							<FormGroup>
								<Label for="password">Neues Passwort</Label>
								<Input type="password" name="password" id="password" placeholder="********"
										className="round"
										value={ resetData.password }
										valid={ resetData.touched && resetData.valid }
										invalid={ resetData.touched && !resetData.valid }
										onChange={ e => setResetData({ ...resetData, touched: true, password: e.target.value }) }
								/>
								<FormFeedback valid>
									<span role="img" aria-label="thumbsup emoji">👍</span>
								</FormFeedback>
								<FormFeedback>
									Passwort unsicher! (8 Zeichen, min. 1 Buchstabe, min. 1 Zahl)
								</FormFeedback>
							</FormGroup>
							<FormGroup>
								<Label for="confirmPassword">Neues Passwort bestätigen</Label>
								<Input type="password" name="confirmPassword" id="confirmPassword"
										placeholder="********"
										className="round"
										value={ resetData.confirmPassword }
										valid={ resetData.touched && resetData.confirmPassword && resetData.confirmPassword === resetData.password }
										invalid={ resetData.touched && (!resetData.confirmPassword || resetData.confirmPassword !== resetData.password) }
										onChange={ e => setResetData({ ...resetData, touched: true, confirmPassword: e.target.value }) }
								/>
								<FormFeedback>
									Passwörter müssen übereinstimmen.
								</FormFeedback>
							</FormGroup>
							{ resetData.status === "ERROR" &&
							<p className="feedback-error">{ resetData.status }</p> }
							<Button color="primary" block className="round mt-4 py-2"
									disabled={ !resetData.valid || resetData.status === "SENT" }>Passwort aktualisieren</Button>
						</Form>
					</div>
					}
					{ resetData.status === "SUCCESS" &&
					<div className="text-center">
						<img className="icon50" src={ CheckIcon } alt="password reset successful" />
						<h1 className="mt-4">Du hast erfolgreich dein Passwort geändert!</h1>
						<p>Du kannst dich jetzt <Link to="/login" className="color-orange">anmelden</Link>.</p>
					</div>
					}
				</>
				}
			</ContentContainer>
		</Layout>
	);
};

export default PasswordReset;
