/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Container, Col, Row } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/main.scss";
import Header from "./Header";
import JBLogo from "../images/jub-800x297-white.png";
// import CookieBanner from "./CookieBanner";

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<Header siteTitle={ data.site.siteMetadata.title }/>
			<main>
				<Container>
					{ children }
				</Container>
				{/* <CookieBanner /> */}
			</main>
			<footer className="mt-5">
				<Row>
					<Col sm="12" md="6" lg={{ size: 4, offset: 2 }}>
						<Row>
							<Col sm="12">
								{/* <h1 className="font-weight-bold">tinify.me</h1> */}
								<img src={ JBLogo } className="footer-logo" alt="Jung&Billig GmbH Logo"/>
							</Col>
							<Col sm="12" className="mt-3">
								<p>
								Deine Werbeagentur aus Bremen. Seit 2015 konzipieren und entwickeln wir hochwertige, digitale Kommunikation für die tollsten Kunden der Welt aus den unterschiedlichsten Branchen.
								</p>
							</Col>
							<Col sm="12" className="mt-3">
								<p>Copyright Jung&Billig GmbH { new Date().getFullYear() }</p>
							</Col>
						</Row>
					</Col>
					<Col sm="12" md="6" lg={{ size: 4, offset: 2 }}>
						<h3>Über uns</h3>
						<ul>
							{/* <li><Link to="/about-us">About Us</Link></li> */}
							<li><Link to="/contact">Kontakt</Link></li>
							<li><Link to="/privacy-policy">Datenschutz</Link></li>
							{/* <li><Link to="/terms-of-service">Terms of Service</Link></li> */}
							<li><Link to="/imprint">Impressum</Link></li>
						</ul>
					</Col>
					{/* <Col sm="6" md="3">
						<h3>Services</h3>
						<ul>
							<li>Instagram</li>
							<li>Facebook</li>
						</ul>
					</Col> */}
				</Row>
			</footer>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
